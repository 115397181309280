import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
  CSpinner,
} from "@coreui/react";

import { useNavigate, useSearchParams} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/AuthProvider";

import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";

const LoginView = () => {
  const auth = useAuth();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { register, formState: errors, handleSubmit } = useForm();

  const onSubmit = (data) => {
    auth.login({
      data: data,
      redirect: searchParams.get("next"),
    });
  };

  useEffect(() => {
    auth.token && navigate("/")
  }, [])

  return (
    <div className="bg-transparent min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol xs={10} lg={5}>
            <CCardGroup>
              <CCard className="p-4 rounded-3 shadow-lg">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    <h2>Вход</h2>
                    <p className="text-secondary">Введите учетные данные</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText
                        style={{ borderColor: "#9da5b1" }}
                        className="bg-white"
                      >
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Логин"
                        autoComplete="username"
                        className={errors.username ? "is-invalid" : ""}
                        {...register("username", { required: true })}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText
                        style={{ borderColor: "#9da5b1" }}
                        className="bg-white"
                      >
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Пароль"
                        autoComplete="current-password"
                        className={errors.password ? "is-invalid" : ""}
                        {...register("password", { required: true })}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <button
                          disabled={auth.loginInProcess}
                          className="btn btn-sm btn-primary px-4 text-white"
                        >
                          {auth.loginInProcess ? (
                            <CSpinner size="sm" color="white" />
                          ) : (
                            "Вход"
                          )}
                        </button>
                      </CCol>
                    </CRow>
                  </CForm>
                  {auth.errorText.length > 0 && (
                    <CAlert color="danger" className="mt-3 rounded-0">
                      {auth.errorText}
                    </CAlert>
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default LoginView;
