import React from "react";
import { CDropdownDivider, CDropdownItem, CNavItem, CNavLink } from "@coreui/react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import Icon from "@mdi/react";

const HeaderItem = ({ item, isMobile, isLastItem }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const itemIsActive =
    location.pathname !== "/" &&
    item.to.startsWith("/" + location.pathname.split("/")[1]);

  return isMobile ? (
    <>
      <CDropdownItem
        size="sm"
        className={
          itemIsActive ? "rounded-0 text-white" : "rounded-0 text-secondary"
        }
        active={itemIsActive}
        onClick={() => navigate(item.to)}
      >
        {item.icon && <Icon path={item.icon} size={0.8} className="me-2" />}
        {item.name}
      </CDropdownItem>
      {!isLastItem && <CDropdownDivider />}
    </>
  ) : (
    <CNavItem>
      <CNavLink
        to={item.to}
        component={NavLink}
        className={itemIsActive ? "text-success" : "text-secondary"}
      >
        {item.icon && <Icon path={item.icon} size={0.8} className="me-2" />}
        {item.name}
      </CNavLink>
    </CNavItem>
  );
};

export default HeaderItem