import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useMessage } from "./MessageProvider";

import fileDownload from "js-file-download";

import api from "../utils/api";
import { useConnection } from "./ConnectionProvider";

export const useErrorHandler = () => {
  const { addMessage } = useMessage();

  const handleError = (err) => {
    let errorText = "Неизвестная ошибка";

    if (err?.response?.data?.detail) {
      errorText = err.response?.data?.detail;
    }
    if (err?.request && err?.request?.data?.status >= 500) {
      errorText = "Внутренняя ошибка сервера";
    }
    addMessage(errorText, "danger");
  };

  return { handleError };
};

export const useFetch = (url = null, defaultFilters = null) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();

  const fetch = ({ url, filters, showLoading = true }) => {
    showLoading && setIsLoading(true);
    api
      .get(url, {params: filters})
      .then((res) => {
        setData(res.data.results);
        setCount(res.data.count);
        showLoading && setIsLoading(false);
      })
      .catch((err) => {
        showLoading && setIsLoading(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
      });
  };

  useEffect(() => {
    url && fetch({ url: url, filters: defaultFilters || {} });
  }, [url]);

  return { data, count, isLoading, hasErrors, denied, fetch };
};

export const useRetrieve = (defaultUrl = null) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();

  const retrieve = ({
    url = null,
    showLoading = true,
    onSuccess = null,
    onFail = null,
  }) => {
    showLoading && setIsLoading(true);
    api
      .get(url || defaultUrl)
      .then((res) => {
        setData(res.data);
        showLoading && setIsLoading(false);
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        showLoading && setIsLoading(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
        onFail && onFail(err);
      });
  };

  useEffect(() => {
    defaultUrl && retrieve({ url: defaultUrl });
  }, [defaultUrl]);

  return { data, isLoading, hasErrors, denied, retrieve };
};

export const useFileRetrieve = () => {
  const [inProcess, setInProcess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();

  const fileRetrieve = ({
    url,
    filename,
    showLoading = true,
    params = null,
  }) => {
    showLoading && setInProcess(true);
    api
      .get(url, { params: params && params, responseType: "blob" })
      .then((res) => {
        fileDownload(res.data, filename);
        showLoading && setInProcess(false);
      })
      .catch((err) => {
        showLoading && setInProcess(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
      });
  };
  return { inProcess, hasErrors, denied, fileRetrieve };
};

export const useDestroy = (baseUrl = null) => {
  const [inProcess, setInProcess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();
  const { addMessage } = useMessage();

  const destroy = ({
    id,
    path = "delete",
    successText = "Объект удален",
    params = null,
    onSuccess = null,
    onFail = null,
  }) => {
    setInProcess(true);
    const url = id ? `${baseUrl}/${id}/${path}` : `${baseUrl}/${path}`;
    api
      .delete(url, { params: params && params })
      .then((res) => {
        setInProcess(false);
        addMessage(successText, "success");
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        setInProcess(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
        onFail && onFail(err);
      });
  };
  return { inProcess, hasErrors, denied, destroy };
};

export const useSave = (baseUrl) => {
  const [inProcess, setInProcess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [denied, setDenied] = useState(false);
  const { handleError } = useErrorHandler();
  const { addMessage } = useMessage();

  const save = ({
    data,
    id = null,
    path = null,
    successText = "Изменения сохранены",
    showSuccessAlert = true,
    onSuccess = null,
    onFail = null,
  }) => {
    let response;
    setInProcess(true);
    if (id) {
      response = api.patch(`${baseUrl}/${id}/${path ? path : ""}`, data);
    } else {
      response = api.post(`${baseUrl}/${path ? path : ""}`, data);
    }
    response
      .then((res) => {
        setInProcess(false);
        showSuccessAlert &&
          addMessage(
            typeof successText === "function" ? successText(res) : successText,
            "success"
          );
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        setInProcess(false);
        if (err?.response?.status === 400 || err?.response?.status > 403) {
          setHasErrors(true);
          handleError(err);
        }
        if (err?.response?.status === 403) {
          setDenied(true);
        }
        onFail && onFail(err);
      });
  };
  return { inProcess, hasErrors, denied, save };
};

export const useOrganisationData = () => {
  const [organisationsData, setOrganisationsData] = useState([]);
  const { isConnected } = useConnection(true);

  const parseData = (data) => {
    const formattedData = data?.map((res) => ({
      name: res.name,
      number: res.number,
      ranges: res.ranges_data.map((o) => ({
        min_value: o.min_value,
        max_value: o.max_value,
      })),
      reusable: res.reusable_codes_data.map((o) => ({ code: o.code })),
      event: res.event_data.name,
    }));
    localStorage.setItem("organisationsData", JSON.stringify(formattedData));
    setOrganisationsData(formattedData);
  };

  const getOrganisationsData = () => {
    const organisationsDataString =
      localStorage.getItem("organisationsData") || "[]";
    return JSON.parse(organisationsDataString);
  };

  useEffect(() => {
    if (isConnected) {
      api
        .get("organisations", { params: { current_event: true } })
        .then((res) => parseData(res.data.results))
        .catch((err) => console.log(err));
    } else {
      setOrganisationsData(getOrganisationsData());
    }
  }, [isConnected]);

  return { organisationsData, getOrganisationsData };
};

export const useLocalStorage = () => {
  const [storageUsedSpace, setStorageUsedSpace] = useState("0");
  const [hasData, setHasData] = useState(false);
  const { organisationsData } = useOrganisationData();

  const getStorageUsedSpace = () => {
    let total = 0;
    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        total += (localStorage[key].length * 2) / 1024;
      }
    }
    return total.toFixed(2);
  };

  const getCodesData = () => {
    const codesDataString =
      localStorage.getItem("codesData") || '{"common": [], "reusable": []}';
    return JSON.parse(codesDataString);
  };

  const getOrganisation = (code) => {
    const isCommonCode = !isNaN(parseFloat(code)) && isFinite(code);
    if (organisationsData.length === 0) {
      return {};
    }
    if (isCommonCode) {
      return organisationsData.find((o) => {
        return o.ranges?.some((range) => {
          return (
            Number(range.min_value) <= Number(code) &&
            Number(code) <= Number(range.max_value)
          );
        });
      });
    } else {
      return organisationsData.find((o) => {
        return o.reusable?.some((reusable) => {
          return reusable.code === code;
        });
      });
    }
  };

  const createCode = ({ code, onSuccess, onFail }) => {
    const isCommonCode = !isNaN(parseFloat(code)) && isFinite(code);
    const codesData = getCodesData();
    const organisationObj = getOrganisation(code);

    if (!organisationObj) {
      return (
        onFail &&
        onFail({
          result: {
            code: code,
            number: "",
            org_name: "",
          },
          created: false,
          alert_level: "danger",
          detail: "Не определена организация",
        })
      );
    }

    if (isCommonCode) {
      // Проверяем, что код не существует в массиве
      if (codesData.common.some((c) => c.code === code)) {
        return (
          onFail &&
          onFail({
            result: {
              code: code,
              number: organisationObj.number,
              org_name: organisationObj.name,
            },
            created: false,
            alert_level: "danger",
            detail: "Такой код уже существует",
          })
        );
      }
      // Добавление кода в массив
      codesData.common.push({ code: code });
      // Устанавливаем флаг наличия данных
      setHasData(true);
      // Выполнение колбека успешного завершения операции
      onSuccess &&
        onSuccess({
          result: {
            code: code,
            number: organisationObj.number,
            org_name: organisationObj.name,
          },
          created: false,
          alert_level: "success",
          detail: "Код сохранен в локальном хранилище",
        });
    } else {
      // Проверяем, что код не существует в массиве
      if (codesData.reusable.some((code) => code.code === code)) {
        return (
          onFail &&
          onFail({
            result: {
              code: code,
              number: organisationObj.number,
              org_name: organisationObj.name,
            },
            created: false,
            alert_level: "danger",
            detail: "Такой код уже существует",
          })
        );
      }
      // Добавление кода в массив
      codesData.reusable.push({ code: code });
      // Устанавливаем флаг наличия данных
      setHasData(true);
      // Выполнение колбека успешного завершения операции
      onSuccess &&
        onSuccess({
          result: {
            code: code,
            number: organisationObj.number,
            org_name: organisationObj.name,
          },
          created: false,
          alert_level: "success",
          detail: "Код сохранен в локальном хранилище",
        });
    }
    localStorage.setItem("codesData", JSON.stringify(codesData));
  };

  const resetCodes = () => {
    localStorage.setItem("codesData", '{"common": [], "reusable": []}');
    setHasData(false);
  };

  useEffect(() => {
    setStorageUsedSpace(getStorageUsedSpace());
  }, []);

  return { createCode, resetCodes, getCodesData, storageUsedSpace, hasData };
};
