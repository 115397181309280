import React from "react";
import {
  CBadge,
  CButton,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavItem,
} from "@coreui/react";

import { useAuth } from "../../../hooks/AuthProvider";
import { useSave } from "../../../hooks/Hooks";
import useScreenType from "react-screentype-hook";

import Icon from "@mdi/react";
import { mdiBell, mdiCheckAll } from "@mdi/js";

import moment from "moment";
import { alertTypesIconMap } from "../../../config";

const NotificationItem = ({ item, onIsReadClick, isLastMessage = false }) => {
  const { id, title, text, type, created_at } = item;
  const screenType = useScreenType();

  const className = screenType.isMobile
    ? "dropdown-menu-custom dropdown-mobile"
    : "dropdown-menu-custom";

  return (
    <>
      <CDropdownItem className={className} onClick={(e) => e.stopPropagation()}>
        <div className="mb-2">
          <Icon
            path={alertTypesIconMap[type] || mdiBell}
            size={0.8}
            className="me-2 text-secondary"
          />
          <small>
            <b>
              {title} ({moment(created_at).local().format("DD.MM.YYYY HH:mm")})
            </b>
          </small>
        </div>

        <div className="mb-2">
          <small>{text}</small>
        </div>
        <CButton
          size="sm"
          className="text-white mb-1"
          onClick={() => onIsReadClick(id)}
        >
          Прочитано
          <Icon path={mdiCheckAll} size={0.8} className="ms-2" />
        </CButton>
      </CDropdownItem>
      {!isLastMessage && <CDropdownDivider />}
    </>
  );
};

const NotificationsHeaderItem = () => {
  const { notifications } = useAuth();
  const screenType = useScreenType();
  const { save } = useSave("notifications");
  const { notificationsCount, getUserNotifications } = useAuth();

  const onIsReadClick = (id) => {
    save({
      id: id,
      data: { is_read: true },
      successText: "Уведомление прочитано",
      onSuccess: () => getUserNotifications(),
    });
  };

  const onAllReadClick = (id) => {
    save({
      data: {},
      successText: "Все уведомления прочитаны",
      onSuccess: () => getUserNotifications(),
    });
  };

  const items = notifications?.map((item, idx) => (
    <NotificationItem
      key={idx}
      item={item}
      isLastMessage={idx + 1 === notifications.length}
      onIsReadClick={onIsReadClick}
    />
  ));

  return (
    <CNavItem>
      <CDropdown>
        <CDropdownToggle color="transparent" size="sm" caret={false}>
          <Icon path={mdiBell} size={0.9} className="text-secondary" />
          {notificationsCount > 0 && (
            <CBadge color="danger" position="top-end" shape="rounded-pill">
              {notificationsCount}
            </CBadge>
          )}
        </CDropdownToggle>
        <CDropdownMenu className={screenType.isMobile ? "me-3" : "me-2"}>
          <CDropdownHeader className="mb-3">
            Уведомления
            {items.length > 0 && (
                <CButton
                  size="sm"
                  className="text-white ms-2"
                  onClick={onAllReadClick}
                >
                  Прочитать все уведомления
                  <Icon path={mdiCheckAll} size={0.8} />
                </CButton>
            )}
          </CDropdownHeader>
          <div
            className={`rounded-3 p-0 py-1 overflow-auto`}
            style={{ maxHeight: "400px" }}
          >
            {items.length > 0 ? (
              items
            ) : (
              <CDropdownItem size="sm" disabled>
                <small>Нет уведомлений</small>
              </CDropdownItem>
            )}
          </div>
        </CDropdownMenu>
      </CDropdown>
    </CNavItem>
  );
};

export default NotificationsHeaderItem;
