import React from "react";
import { CNavItem } from "@coreui/react";

import { useDispatch, useSelector } from "react-redux";

import Icon from "@mdi/react";
import { mdiFullscreen, mdiFullscreenExit } from "@mdi/js";

const FullScreenHeaderItem = () => {
  const fullScreen = useSelector((state) => state.fullScreen);
  const dispatch = useDispatch();

  const onFullScreenChange = () => {
    dispatch({ type: "set", fullScreen: !fullScreen });
  };

  return (
    <CNavItem>
      <div className="text-secondary mt-1" onClick={onFullScreenChange}>
        <Icon
          path={fullScreen ? mdiFullscreenExit : mdiFullscreen}
          size={1}
          className="me-2"
        />
        {/* {fullScreen ? "Обычный" : "Полноэкранный"} */}
      </div>
    </CNavItem>
  );
};
export default FullScreenHeaderItem;
