import React, { useContext, createContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import api from "../utils/api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [loginInProcess, setLoginInProcess] = useState(false);
  const [userIsLoading, setUserIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([])
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [notificationsIsLoading, setNotificationsIsLoading] = useState(false)
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();

  const getUserData = () => {
    setUserIsLoading(true);
    api
      .get("auth/user")
      .then((res) => {
        setUser(res.data);
        setUserIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setUserIsLoading(false);
      });
  };

  const getUserNotifications = () => {
    setNotificationsIsLoading(true)
    api
      .get("notifications", {params: {page_size: 5}})
      .then((res) => {
        setNotifications(res.data.results);
        setNotificationsCount(res.data.count);
        setNotificationsIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setNotificationsIsLoading(false);
      });
  }

  const hasPerms = (checkPerms) => {
    if (checkPerms === "allow_any") {
      return true;
    }

    if (Array.isArray(checkPerms)) {
      return checkPerms.every((perm) => user?.permissions?.includes(perm));
    }

    return user?.permissions?.includes(checkPerms);
  };

  const login = ({ data, redirect }) => {
    setLoginInProcess(true);
    setErrorText("");
    api
      .post("auth/login/", data)
      .then((res) => {
        localStorage.setItem("token", res.data.access);
        setToken(res.data.access);
        setLoginInProcess(false);
        navigate(redirect || "/");
      })
      .catch((err) => {
        setErrorText(err.response?.data?.non_field_errors || err.response?.data?.detail || "Ошибка входа");
        setLoginInProcess(false);
      });
  };

  const logout = () => {
    api
      .post("auth/logout/")
      .then((res) => {
        localStorage.removeItem("token");
        setUser(null);
        setToken("");
        navigate("/login");
      })
      .catch((err) => {
        localStorage.removeItem("token");
        setUser(null);
        setToken("");
        setErrorText(err.response || "Ошибка выхода из учетной записи");
      });
  };

  const handleStorageChange = (event) => {
    if (event.key !== "token") {
      return;
    }
    resetState();
  };

  const resetState = () => {
    setUser(null);
    setToken(null);
    navigate("/login");
  };

  useEffect(() => {
    if (token && !user) {
      getUserData()
      getUserNotifications()
    }
  }, [token]);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("tokenExpired", resetState);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.addEventListener("tokenExpired", resetState);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        loginInProcess,
        userIsLoading,
        errorText,
        notifications,
        notificationsCount,
        notificationsIsLoading,
        login,
        logout,
        hasPerms,
        resetState,
        getUserNotifications,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
