import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavItem,
  CSpinner,
} from "@coreui/react";

import { useAuth } from "../../../hooks/AuthProvider";
import Icon from "@mdi/react";
import { mdiLogout } from "@mdi/js";

const UserHeaderItem = () => {
  const auth = useAuth();
  const userName = auth.user?.full_name
    ? auth.user?.full_name + " | " + auth.user?.username
    : auth.user?.username;

  return (
    <CNavItem>
      <CDropdown>
        <CDropdownToggle
          color="transparent"
          size="sm"
          className="text-secondary"
          caret={false}
        >
          {auth.userIsLoading ? (
            <CSpinner size="sm" />
          ) : (
            <span>{userName}</span>
          )}
        </CDropdownToggle>
        <CDropdownMenu className="rounded-3">
          <CDropdownItem size="sm" onClick={auth.logout} >
            <small>Выход из учетной записи</small>
            <Icon path={mdiLogout} size={0.7} className="ms-2 text-secondary"/>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </CNavItem>
  );
};
export default UserHeaderItem;
