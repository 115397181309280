import React from "react";

import {
  mdiAccount,
  mdiCalendarStarFourPoints, mdiChartAreaspline, mdiCheckboxMarkedCircleAutoOutline,
  mdiCogOutline,
  mdiDomain,
  mdiHome,
  mdiHomeMapMarker,
  mdiHuman,
  mdiMapMarker,
  mdiMonitorDashboard,
  mdiQrcodePlus,
  mdiQrcodeScan,
} from "@mdi/js";

const DashboardDetailView = React.lazy(
  () => import("./views/dashboard/DashboardDetailView"),
);

const TaskListView = React.lazy(
  () => import("./views/tasks/main/TaskListView"),
);
const TaskDetailView = React.lazy(() => import("./views/tasks/main/detail/TaskDetailView"));
const TaskManageView = React.lazy(
    () => import("./views/tasks/main/TaskManageView"),
);

const RegionTaskDetailView = React.lazy(
    () => import("./views/tasks/regions/RegionTaskDetailView"),
);

const FederalDistrictListView = React.lazy(() =>
  import("./views/federal-districts/FederalDistrictListView")
);

const FederalDistrictManageView = React.lazy(() =>
  import("./views/federal-districts/FederalDistrictManageView")
);

const RegionListView = React.lazy(() =>
  import("./views/regions/RegionListView")
);
const RegionDetailView = React.lazy(() =>
  import("./views/regions/RegionDetailView")
);
const RegionManageView = React.lazy(() =>
  import("./views/regions/RegionManageView")
);

const DomainListView = React.lazy(() =>
  import("./views/domains/DomainListView")
);
const DomainManageView = React.lazy(() =>
  import("./views/domains/DomainManageView")
);

const UserListView = React.lazy(() => import("./views/users/UserListView"));
const UserManageView = React.lazy(() => import("./views/users/UserManageView"));

const Index = React.lazy(() => import("./views/Index"));
const Page404 = React.lazy(() => import("./components/pages/PageError"));


const SettingManageView = React.lazy(() =>
  import("./views/settings/SettingManageView")
);

export const navbarItems = [
  {
    to: "/dashboard",
    name: "Дашборд",
    permission: "view_dashboard",
    icon: mdiChartAreaspline,
  },
  {
    to: "/tasks/list",
    name: "Задачи",
    permission: "view_task",
    icon: mdiCheckboxMarkedCircleAutoOutline,
  },
  // {
  //   to: "/federal-districts/list",
  //   name: "Федеральные округа",
  //   permission: "view_federaldistrict",
  //   icon: mdiHomeMapMarker,
  // },
  {
    to: "/regions/list",
    name: "Регионы",
    permission: "view_region",
    icon: mdiMapMarker,
  },
  {
    to: "/users/list",
    name: "Пользователи",
    permission: "view_customuser",
    icon: mdiAccount,
  },
  {
    to: "/domains/list",
    name: "Настройка доменов",
    permission: "view_tasklinkalloweddomain",
    icon: mdiDomain,
  },
  {
    to: "/settings",
    name: "Настройки системы",
    permission: "change_setting",
    icon: mdiCogOutline,
  },
];

export const routes = [
  {
    path: "*",
    name: "Page404",
    element: Page404,
    permission: "allow_any",
  },
  {
    path: "/",
    name: "Index",
    element: Index,
    permission: "allow_any",
  },
  {
    path: "/dashboard",
    name: "DashboardDetailView",
    element: DashboardDetailView,
    permission: "view_dashboard",
  },
  {
    path: "/tasks/list",
    name: "TaskListView",
    element: TaskListView,
    permission: "view_task",
  },
  {
    path: "/tasks/:id/detail",
    name: "TaskDetailView",
    element: TaskDetailView,
    permission: "view_task",
  },
  {
    path: "/tasks/create",
    name: "TaskCreateView",
    element: TaskManageView,
    permission: "add_task",
  },
  {
    path: "/tasks/:id/edit",
    name: "TaskEditView",
    element: TaskManageView,
    permission: "change_task",
  },
  {
    path: "/tasks/regions/:id/detail",
    name: "RegionTaskDetailView",
    element: RegionTaskDetailView,
    permission: ["view_regiontask", "change_regiontask"],
  },
  {
    path: "/federal-districts/list",
    name: "FederalDistrictListView",
    element: FederalDistrictListView,
    permission: "view_federaldistrict",
  },
  {
    path: "/federal-districts/create",
    name: "FederalDistrictCreateView",
    element: FederalDistrictManageView,
    permission: "add_federaldistrict",
  },
  {
    path: "/federal-districts/:id/edit",
    name: "FederalDistrictEditView",
    element: FederalDistrictManageView,
    permission: "change_federaldistrict",
  },
  {
    path: "/regions/list",
    name: "RegionListView",
    element: RegionListView,
    permission: "view_region",
  },
  {
    path: "/regions/:id/detail",
    name: "RegionDetailView",
    element: RegionDetailView,
    permission: "view_region",
  },
  {
    path: "/regions/create",
    name: "RegionCreateView",
    element: RegionManageView,
    permission: "add_region",
  },
  {
    path: "/regions/:id/edit",
    name: "RegionEditView",
    element: RegionManageView,
    permission: "change_region",
  },
  {
    path: "/users/list",
    name: "UsersListView",
    element: UserListView,
    permission: "view_customuser",
  },
  {
    path: "/users/create",
    name: "UserCreateView",
    element: UserManageView,
    permission: "add_customuser",
  },
  {
    path: "/users/:id/edit",
    name: "UserEditView",
    element: UserManageView,
    permission: "change_customuser",
  },
  {
    path: "/domains/list",
    name: "DomainListView",
    element: DomainListView,
    permission: "view_tasklinkalloweddomain",
  },
  {
    path: "/domains/create",
    name: "DomainCreateView",
    element: DomainManageView,
    permission: "add_tasklinkalloweddomain",
  },
  {
    path: "/domains/:id/edit",
    name: "DomainEditView",
    element: DomainManageView,
    permission: "change_tasklinkalloweddomain",
  },
  {
    path: "/settings",
    name: "SettingManageView",
    element: SettingManageView,
    permission: "change_setting",
  },
];
